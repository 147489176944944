<script lang="ts">
	import Checkbox from '$lib/components/ui/Checkbox.svelte';
	import Link from '$lib/components/ui/Link.svelte';
	import LoginButton from './LoginButton.svelte';
	import { onBeforeClose } from 'svelte-modals';
	import { user } from '$lib/stores/user';
	import { onMount } from 'svelte';
	import { Confetti } from 'svelte-confetti';
	import { toast } from 'svelte-sonner';
	import { goto } from '$app/navigation';
	import { apiFetch } from '$lib/utils/fetch';
	import Card from './Card.svelte';
	let playpark_stadgar: boolean = $state(false);
	let renew_year = new Date().getFullYear();
	let confetti: boolean = $state(false);
	let username = $user ? $user.username : '';

	type State = 'default' | 'success' | 'loading' | 'error';
	interface Props {
		c_state?: State;
	}

	let { c_state: renew_state = $bindable('default') }: Props = $props();
	renew_state = 'default';

	onMount(() => {
		if (!$user) {
			renew_state = 'loading';
			toast.error('Du måste vara inloggad för att förnya ditt medlemskap');
			goto('/');
		}
	});

	async function sendRenewMembership() {
		renew_state = 'loading';
		const res = await apiFetch('auth/renew', {
			method: 'POST',
			body: { agreement_to_stadgar: playpark_stadgar }
		})
			.then((res) => {
				if (res.message === 'User has been renewed') {
					renew_state = 'success';
					toast.success('Medlemskap förnyat!');
					confetti = true;
					setTimeout(() => {
						confetti = false;
						goto('/');
					}, 600);
				} else {
					toast.error('Något gick fel, försök igen senare');
				}
			})
			.catch((err) => {
				if (err.data.error.BadRequest === 'User has already renewed this year') {
					renew_state = 'error';
					toast.error('Du har redan förnyat ditt medlemskap för detta år');
					setTimeout(() => {
						goto('/');
					}, 400);
					return;
				} else {
					renew_state = 'default';
					toast.error('Något gick fel, försök igen senare');
					console.log(err);
				}
			});
	}

	onBeforeClose(() => {
		if (renew_state === 'default' || renew_state === 'loading') {
			return false;
		}
	});
</script>

<Card>
	<div>
		<h1 class="text-2xl font-semibold text-left text-white">Förnya ditt medlemskap</h1>
		<span class="flex items-center py-1 space-x-2">
			<h4 class="text-sm xl:text-base text-zinc-400">Problem?</h4>
			<Link href="https://discord.playpark.se">Kontakta oss</Link>
		</span>
	</div>
	<p class="text-white">
		Hej, <Link href="/profile/{username}">{username}</Link> du behöver förnya ditt medlemskap i Playpark
		spelförening för räkenskapsåret {renew_year}
	</p>
	<Checkbox required id="playpark_stadgar" name="playpark_stadgar" bind:checked={playpark_stadgar}>
		<label for="playpark_stadgar" class="text-sm text-white">
			Jag godkänner <a href="/stadgar" class="text-blue-400 underline">Playparks stadgar</a>
		</label>
	</Checkbox>
	<LoginButton
		button_state={renew_state}
		type="submit"
		style="green"
		disabled={!playpark_stadgar}
		functionToRun={sendRenewMembership}
	>
		<div class="relative flex items-center justify-center w-full px-4">Förnya medlemskap</div>
	</LoginButton>
</Card>

{#if confetti}
	<div
		style="
 z-index: 1000;
 position: fixed;
 bottom: -100vh;
 left: 0;
 height: 100vh;
 width: 100vw;
 display: flex;
 justify-content: center;
 pointer-events: none;"
	>
		<Confetti
			x={[-3, 3]}
			y={[0, 5]}
			delay={[0, 250]}
			duration={2000}
			amount={200}
			fallDistance="100vh"
		/>
	</div>
{/if}
